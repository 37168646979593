<template>
  <div class="Technology">
    <img class="back_iphone" src="../assets/technology/Iphone_banner.png" />
    <div class="Technology_body">
      <div class="body_video">
        <div class="body_left">
          <div class="body_title">视频教程演示</div>
          <div class="body_text">
            请输入文字介绍请输入文字介绍请输入文字介绍
            请输入文字介绍请输入文字介绍请输入文字介绍 请输入文字介绍
          </div>
          <a-button class="body_btn">查看</a-button>
        </div>
        <div class="body_right">
          <img src="@/assets/technology/Group 9348.png" alt="" />
        </div>
      </div>
      <div class="body_video">
        <div class="body_left">
          <div class="body_title">视频教程演示</div>
          <div class="body_text">
            请输入文字介绍请输入文字介绍请输入文字介绍
            请输入文字介绍请输入文字介绍请输入文字介绍 请输入文字介绍
          </div>
          <a-button class="body_btn">查看</a-button>
        </div>
        <div class="body_right">
          <img src="@/assets/technology/Group 9349.png" alt="" />
        </div>
      </div>
      <div class="body_video">
        <div class="body_left">
          <div class="body_title">视频教程演示</div>
          <div class="body_text">
            请输入文字介绍请输入文字介绍请输入文字介绍
            请输入文字介绍请输入文字介绍请输入文字介绍 请输入文字介绍
          </div>
          <a-button class="body_btn">查看</a-button>
        </div>
        <div class="body_right">
          <img src="@/assets/technology/Group 9350.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.Technology {
  width: 100%;
  .back_iphone {
    width: 100%;
    margin-bottom: 5%;
  }
  .Technology_body {
    display: flex;
    flex-direction: column;
    .body_video {
      height: 100%;
      display: flex;
      padding: 0 10%;
      padding-bottom: 5%;
      .body_left {
        flex: 0.4;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding-left: 10px;
        border-left: 2px solid #14274a;
        margin: 3% 3% 3% 0;
        padding: 0 3%;
        .body_title {
          font-size: 50px;
          font-family: Roboto, Roboto-SemiBold;
          font-weight: Bold;
          color: #333333;
          line-height: 50px;
          letter-spacing: 0.12px;
        }
        .body_text {
          font-size: 25px;
          font-family: Montserrat, Montserrat-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 40px;
        }
        .body_btn {
          width: 170px;
          height: 43px;
          background: #ffac1c;
          font-size: 18px;
          font-family: Montserrat, Montserrat-Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          letter-spacing: 0.1px;
          border-radius: 10px;
          border: none;
        }
      }
      .body_right {
        flex: 0.6;
        img {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      .body_video {
        flex-direction: column;
      }
    }
  }
}
</style>
